export const OurCompany = (props) => {
  return (
    <div id='our_company' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>Who V Are</h2>
          <p>
          Founded in 2016 as a subsidiary of the BEAM Enterprise which had been established earlier in 2004, V Wise’s main function is to expand wide-ranging services for IT solutions whilst BEAM Enterprise focuses mainly on business and mobile application. The demerger allows both companies to focus on the area of expertise and to ensure that our clients receive the excellent customer experience and best-in-class services. We provide expertise in customized and turn-key application development project with Microsoft technology Java/.Net programming and professional IT/Business Process outsourcing.
          </p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-xs-6 col-md-3'>
                  {' '}
                  <i className={d.icon}></i>
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              ))
            : 'Loading...'}
        </div>
      </div>
    </div>
  )
}
